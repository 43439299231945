@font-face {
  font-family: "ProductSans";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/ProductSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ProductSans";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/ProductSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "ProductSans";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/ProductSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "ProductSans";
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/ProductSans-Italic.ttf") format("truetype");
}

.fontFamilyRoboto {
  font-family: Roboto !important;
}
/* --------------------------------------------------------Copied from dash----------------------------------------------------------- */
iframe{max-width: 100%; width: 100%;}
.imgWrap{width: 100%;}
.imgWrap iframe{height: 300px;}
.ytp-chrome-top, .ytp-chrome-bottom{display: none;}
.blogDetailPage iframe{min-height: 500px}
html {
  font-size: 56.25%;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 48.25%;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

body {
  overflow: -moz-scrollbars-none;
}

body {
  -ms-overflow-style: none;
}

.displayNone {
  display: none !important;
}

.loadingCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#landingAppBar {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.dispFlex {
  display: flex;
}

.flexCenterColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexCenterRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-mg-48 {
  margin-right: 48px !important;
}

.right-mg-32 {
  margin-right: 32px !important;
}

.right-mg-16 {
  margin-right: 16px !important;
}

.bottom-mg-16 {
  margin-bottom: 16px !important;
}

.bottom-mg-8 {
  margin-bottom: 8px !important;
}

.appBarLogo {
  margin-top: 16px;
  margin-left: 48px;
  min-height: 40px;
}

.stickyBarLogo {
  margin-left: 48px;
  min-height: 40px;
}

#landingAppBar {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
}

#arrowLogo {
  height: 40px;
  margin-right: 8px;
}

#textLogo {
  height: 25px;
}

.headerSection {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 62%,
    rgba(128, 37, 32, 1) 62%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px 48px;
  height: 100vh;
}

.headerSectionNew {
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px 48px;
  height: 100vh;
}

.headerSection .react-player__preview {
  border-radius: 8px;
}

.headerSection .react-player__shadow {
  border-radius: 8px;
}

.headerSection .player-wrapper {
  border-radius: 8px;
}

.customPricingToggle .Mui-selected {
  background-color: #801422 !important;
  color: white !important;
}

.frame-laptop {
  background: url("./assets/frame.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 311px;
  width: 483px;
  padding: 14px 50px 44px 53px;
}

.inner-lap-block {
  width: calc(100% - 28px);
  position: relative;
  display: inline-block;
  height: 292px;
}

.slide1 {
  background: url("./assets/Dashboard Screen Shot 2.png") no-repeat center;
  background-size: 100% 100%;
  /* animation: fade 8s infinite; */
  /* -webkit-animation: fade 8s infinite; */
}

/* .slide2{
  background: url("./assets/Dashboard Screen Shot 2.png") no-repeat center;
  background-size: 100% 100%;
  animation: fade2 8s infinite;
  -webkit-animation: fade2 8s infinite;
} */

.slide1,
.slide2 {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* @keyframes fade
{
  0%   {opacity:1}
  50% {opacity:0}
  100% { opacity: 1}
}
@keyframes fade2
{
  0%   {opacity:0}
  50% { opacity: 1}
  100% { opacity: 0}
} */

.videoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background: url("./assets/video-thumbnail-2.jpg") no-repeat center;
  background-size: cover;
  background-position: top center;
}

.secondaryVideoContainer {
  height: calc(100vh - 57px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background: url("./assets/video-thumbnail.jpg") no-repeat center;
  background-size: cover;
  background-position: top center;
  background-color: rgba(0, 0, 0, 0.65);
  background-blend-mode: multiply;
}

.videoModal {
  display: none; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.75); /* Black w/ opacity */
}

.showModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content/Box */
.video-modal-content {
  background-color: transparent;
  display: flex;
  justify-content: center;
  position: relative;
  left: 36px;
}

@media only screen and (min-width: 300px) and (max-width: 769px) {
  #landingAppBar {
    padding: 0 12px !important;
  }
}

@media only screen and (max-width: 768px) {
  .stickyBarLogo {
    margin-left: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .stickyBarLogo {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 769px) {
  .changeRightMargin {
    margin-right: 16px !important;
  }

  .video-modal-content {
    flex-direction: column;
    justify-content: center;
    position: relative;
    bottom: 20px;
    left: 0px;
  }

  .secondaryVideoContainer {
    height: calc(100vh - 56px);
  }

  .appBarLogo {
    margin-left: 32px;
  }

  .headerSection {
    background: white;
    padding: 0px;
    height: auto;
  }

  #arrowLogo {
    height: 40px;
    margin-right: 4px;
  }

  #textLogo {
    height: 20px;
  }

  .headerSection .react-player__preview {
    border-radius: 0px;
  }

  .headerSection .react-player__shadow {
    border-radius: 0px;
  }

  #pricingTabsMobile .MuiTabs-indicator {
    background-color: white !important;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1365px) {
  .video-modal-content {
    flex-direction: column;
    justify-content: center;
    position: relative;
    bottom: 20px;
    left: 0px;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1200px) {
  .frame-laptop {
    height: 277px;
    width: 443px;
  }

  .inner-lap-block {
    height: 264px;
  }
}

/*--------------------Scrollbar Inside ---------------*/
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .headerSection .react-player__preview {
    border-radius: 8px;
  }

  .headerSection .react-player__shadow {
    border-radius: 8px;
  }
}

#recaptcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.lowerButtonContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 36px;
  align-items: flex-start;
}

.contentContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.successImg {
  height: 300px;
}

/* ---------------------------------------------------trainer contact form--resposive----------------------------------------------------- */
@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .selectedButton {
    background-color: #fff !important;
  }
  .lowerButtonContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }
  #recaptcha-container {
    margin-bottom: 20px;
  }
}

.hex {
  width: 200px;
  display: inline-block;
  filter: url('#round');
  z-index: 2 !important;
}

.hex::before {
  content: '';
  display: block;
  padding-top: 115%;
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(50px) !important;
  -webkit-clip-path: polygon(0% 25%,0% 75%,50% 100%,100% 75%,100% 25%,50% 0%);
  clip-path: polygon(0% 25%,0% 75%,50% 100%,100% 75%,100% 25%,50% 0%);
  shape-outside: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  -webkit-shape-outside: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.planCard {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);
}

.noMargin {
  margin: 0px !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* OVERRIDER CAROUSEL PACKAGE STYLES */
.alice-carousel__prev-btn {
  text-align: left !important;
  margin-top: 50px !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
}
.alice-carousel__next-btn {
  text-align: right !important;
  margin-top: 50px !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
}
.alice-carousel__stage {
  display: flex !important;
}
.alice-carousel__stage-item {
  flex: 1 0 auto !important;
  height: auto !important;
}
/* OVERRIDER CAROUSEL PACKAGE STYLES ENDS*/
